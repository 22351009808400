<template>
  <div class="search">

    <el-card style="margin-bottom:12px">
      <div class="flex">
        <div class="cTitle" @click="more = !more"> 
          <i v-if="!more" class="el-icon-caret-right"></i>
          <i v-if="more" class="el-icon-caret-bottom"></i>
           高级筛选</div>
        <div class="btnAction">
          <el-input v-model="searchForm.nickName" placeholder="请输入商品信息" style="width:160px;margin-right:8px"></el-input>
          <el-button type="primary" plain @click="getDataList" size="mini">搜索</el-button>
        </div>
      </div>
      <div class="flex" style="margin-top: 10px;" v-if="more">
          <el-input v-model="searchForm.tel" placeholder="请输入手机号"
            style="width:160px;margin-right:8px"></el-input>
          <el-input v-model="searchForm.userName" placeholder="请输入登录帐号"
            style="width:160px;margin-right:8px"></el-input>
        </div>
    </el-card>

    <el-card>
      <div class="flex">
        <div class="cTitle">人员管理</div>
        <div class="btnAction">
          <el-button type="primary" plain round @click="add" size="mini">添加帐号信息</el-button>
          <!-- <el-button  type="primary" plain
            round @click="delAll" size="mini">批量删除</el-button> -->
        </div>
      </div>
      <div style="margin-top:16px">

        <vxe-table align="center" ref="table" :export-config="{
          types: ['csv'],
          isHeader: true,
        }" :print-config="{}" stripe size="medium" :height="height - 147 - 50 - 95" :loading="loading"
          :column-config="{ resizable: true }" :row-config="{ isHover: true }" :data="data">
          <vxe-column fixed="left" type="checkbox" width="40"></vxe-column>

          <vxe-column field="nickName" title="姓名">
            <template #header="{ column }">
              <span style="color: #909399">{{ column.title }}</span>
            </template>
            <template #default="{ row }">
              <span>{{ row.nickName }}</span>
            </template>
          </vxe-column>

          <vxe-column field="手机号码" title="手机号码">
            <template #header="{ column }">
              <span style="color: #909399">{{ column.title }}</span>
            </template>
            <template #default="{ row }">
              <span>{{ row.tel }}</span>
            </template>
          </vxe-column>

          <vxe-column field="userName" title="登录帐号">
            <template #header="{ column }">
              <span style="color: #909399">{{ column.title }}</span>
            </template>
            <template #default="{ row }">
              <span>{{ row.userName }}</span>
            </template>
          </vxe-column>

          <vxe-column field="userName" title="绑定推送">
            <template #header="{ column }">
              <span style="color: #909399">{{ column.title }}</span>
            </template>
            <template #default="{ row }">
              <span>
                <el-button size="mini" v-if="!row.wxOpenId" style="color: red;" @click="bindWeiXin(row)"
                  type="text">点击绑定</el-button>
                <el-button size="mini" v-else @click="bindWeiXin(row)" type="text">已绑定</el-button>
              </span>
            </template>
          </vxe-column>

          <vxe-column field="userName" title="用户权限">
            <template #header="{ column }">
              <span style="color: #909399">{{ column.title }}</span>
            </template>
            <template #default="{ row }">
              <el-tag type="success" v-if="row.managerType == 1">管理员</el-tag>
              <el-tag type="default" v-else>普通用户</el-tag>
            </template>
          </vxe-column>
          <!--        <vxe-column field="yjFinishTime" title="预期完成时间" width="250"></vxe-column>-->
          <vxe-column fixed="right" title="操作" width="230">
            <template #default="{ row }">
              <div style="display:flex">
                <el-button v-if="row.managerType == 1" size="mini" plain type="primary" @click="settingPrmisson(row)">设为普通用户</el-button>
                <el-button v-else  size="mini" plain type="primary" @click="settingPrmisson(row)">设为管理员</el-button>
                
                <el-button size="mini" plain type="primary" @click="edit(row)">编辑</el-button>
                <el-button size="mini" plain type="primary" @click="remove(row)">删除</el-button>
              </div>

            </template>
          </vxe-column>
          <vxe-column fixed="right" width="8" />
        </vxe-table>
        <vxe-pager :loading="loading" :current-page="page.currentPage" :page-size="page.pageSize" :total="page.total"
          :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
          @page-change="handlePageChange1">
        </vxe-pager>



        <addEdit :data="form" :type="showType" v-model="showDrawer" @on-submit="getDataList" />
       
        <Modal title='微信扫码绑定(如若已绑定请刷新列表后查看)' v-model="modalVisibleWeiXin" :mask-closable="false" :width="720">

          <div class="qr" style="text-align: center;">
              <div style="padding: 2px;">绑定人信息：{{ selectItem.nickName}}({{ selectItem.userName }})</div>
              <div  style="padding: 2px;">是否绑定：<span style="color:green" v-if="selectItem.wxOpenId">已绑定</span><span v-else style="color: red;">待绑定</span></div>
            <vue-qrcode :value="qrcodeText"
              :options="{ width: 230 }"></vue-qrcode>

          </div>

          <p slot="footer"></p>

        </Modal>

      </div>

      

    </el-card>


  </div>
</template>

<script>
// 根据你的实际请求api.js位置路径修改
import { getCheckUserPage, delCheckUser,setTingPrmisson } from "@/api/index";
// 根据你的实际添加编辑组件位置路径修改
import addEdit from "./addEdit.vue";
import { shortcuts } from "@/libs/shortcuts";
import VueQrcode from '@chenfengyuan/vue-qrcode'
export default {
  name: "buildingConfig",
  components: {
    addEdit,
    VueQrcode
  },
  data() {
    return {
      more:false,
      selectItem:{},
      height: 600,
      modalVisibleWeiXin:false,
      tableSize: "default",
      openTip: true, // 显示提示
      showType: "0", // 添加或编辑标识
      showDrawer: false, // 显示添加编辑抽屉
      loading: true, // 表单加载状态
      searchForm: { // 搜索框初始化对象
        userType: 1 ,
        pageNumber: 1, // 当前页数
        pageSize: 10, // 页面大小
        sort: "createTime", // 默认排序字段
        order: "desc", // 默认排序方式
      },
      selectList: [], // 多选数据
      form: {},
      data: [], // 表单数据
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
      },
      qrcodeText:"",

    };
  },
  methods: {
    settingPrmisson(v){
      setTingPrmisson({id:v.id}).then(res=>{
        this.getDataList();
      })
    },
    bindWeiXin(v){
      this.selectItem = v 
      this.qrcodeText = "https://www.zmxxkj.net.cn/h5/pages/bind/weixinBind?id=" + v.id
      this.modalVisibleWeiXin = true
    },
    init() {
      this.getDataList();
    },
    handlePageChange1({ currentPage, pageSize }) {
      this.page.currentPage = currentPage
      this.page.pageSize = pageSize
      this.getDataList()
    },
    getDataList() {
      this.loading = true;

      getCheckUserPage(Object.assign({
        current: this.page.currentPage,
        size: this.page.pageSize
      }, this.searchForm)).then(res => {
        this.loading = false;
        if (res.success) {
          this.data = res.result.records;
          this.page.total = res.result.total
        }
      });
    },
    add() {
      this.showType = "2";
      this.showDrawer = true;
    },
    edit(v) {
      // 转换null为""
      for (let attr in v) {
        if (v[attr] == null) {
          v[attr] = "";
        }
      }
      let str = JSON.stringify(v);
      let data = JSON.parse(str);
      this.form = data;
      this.showType = "1";
      this.showDrawer = true;
    },
    remove(v) {
      this.$Modal.confirm({
        title: "确认删除",
        // 记得确认修改此处
        content: "您确认要删除该条数据?",
        loading: true,
        onOk: () => {
          // 删除
          delCheckUser({ ids: v.id }).then(res => {
            this.$Modal.remove();
            if (res.success) {
              this.$Message.success("操作成功");
              this.getDataList();
            }
          });
        }
      });
    },
    delAll() {
      let selectRecords = this.$refs.table.getCheckboxRecords()
      if (selectRecords.length <= 0) {
        this.$Message.warning("您还未选择要删除的数据");
        return;
      }
      this.$Modal.confirm({
        title: "确认删除",
        content: "您确认要删除所选的 " + selectRecords.length + " 条数据?",
        loading: true,
        onOk: () => {
          let ids = "";
          selectRecords.forEach(function (e) {
            ids += e.id + ",";
          });
          ids = ids.substring(0, ids.length - 1);
          // 批量删除
          delCheckUser({ ids: ids }).then(res => {
            this.$Modal.remove();
            if (res.success) {
              this.$Message.success("操作成功");
              this.getDataList();
            }
          });
        }
      });
    }
  },
  mounted() {
    this.init();

    this.height = (window.innerHeight)
    window.onresize = () => {
      return (() => {
        this.height = (window.innerHeight)
      })()
    }
  }
};
</script>
<style lang="less">
@import "@/styles/table-common.less";

.flex {
  display: flex;
}

.cTitle {
  cursor: pointer;
  color: black;
  font-size: 16px;
  font-weight: 800;
}

.btnAction {
  flex: 1;
  text-align: right;
}

.vxe-pager.is--perfect .vxe-pager--num-btn:not(.is--disabled).is--active {
  background-color: #b6986d;
}

.is--checked.vxe-checkbox,
.is--checked.vxe-checkbox .vxe-checkbox--icon,
.is--checked.vxe-custom--option,
.is--checked.vxe-custom--option .vxe-checkbox--icon,
.is--checked.vxe-export--panel-column-option,
.is--checked.vxe-export--panel-column-option .vxe-checkbox--icon,
.is--checked.vxe-table--filter-option,
.is--checked.vxe-table--filter-option .vxe-checkbox--icon,
.is--indeterminate.vxe-checkbox,
.is--indeterminate.vxe-checkbox .vxe-checkbox--icon,
.is--indeterminate.vxe-custom--option,
.is--indeterminate.vxe-custom--option .vxe-checkbox--icon,
.is--indeterminate.vxe-export--panel-column-option,
.is--indeterminate.vxe-export--panel-column-option .vxe-checkbox--icon,
.is--indeterminate.vxe-table--filter-option,
.is--indeterminate.vxe-table--filter-option .vxe-checkbox--icon,
.vxe-table--render-default .is--checked.vxe-cell--checkbox,
.vxe-table--render-default .is--checked.vxe-cell--checkbox .vxe-checkbox--icon,
.vxe-table--render-default .is--indeterminate.vxe-cell--checkbox,
.vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon {
  color: #b6986d
}</style>